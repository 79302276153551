body {
  font-family: 'Serotiva', sans-serif;
}
h1 {
  font-family: 'SerotivaExtBd', sans-serif;
  font-weight: normal;
}
p {
  font-family: 'SerotivaLight', sans-serif;
  font-weight: normal;
}
.h-80vh {
  height: 800px !important;
}
.mh-225 {
  max-height: 225px !important;
  height: 225px !important;
}

.amenities-grid {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  grid-template-rows: repeat(2, 1fr) !important;
  height: calc(800px - 450px - 4px) !important;
  background-color: black !important;
  padding: 2rem !important;
  gap: 1rem !important;
}

@media (max-width: 1024px) {
  .h-80vh {
    height: 60vh !important;
  }
  .flex.w-100.flex-wrap {
    grid-template-rows: repeat(2, 150px) !important;
  }
  .amenities-grid {
    height: auto !important;
    padding: 1rem !important;
  }
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
} 
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.guide-imgs {
    width: 100%;
    max-height: -webkit-fill-available;
}
.drop-shadow-md {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}
.strike-text {
  width: 100%;
  text-align: left;
  border-radius: 2px;
  border-bottom: 1px solid #a7a098;
  line-height: 0px;
  margin: 10px 0 20px;
}
.strike-text span {
  background: #fff;
  padding: 0 10px;
}
.tracking-1\.2px {
  letter-spacing: 1.2px;
}
.max-w-105{
  max-width: 105% !important;
}
.featured-logo-n img{
  width: 20rem;
  height: auto;
}
.featured-logo-n p {
  font-family: Serotiva;
    font-weight: 200;
    letter-spacing: 3px;
    font-size: 3rem;
}
.explore-button {
  border-radius: 50px !important;
  letter-spacing: 1.3px !important;
  height: 0 !important;
  padding: 20px 30px 18px !important;
}
.featured-suites-section-hp h2, .featured-suites-section-hp h2 span{
  font-size: 4rem !important;
}
.npbutton {
  height: 3.75rem !important;
  width: 3.75rem !important;
  border-radius: 0 !important;
  margin-right: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.npbutton svg{
  height: 2rem;
  width: 2rem;
}

.inline-flex.items-center.justify-center.rounded-md.font-medium.transition-colors.focus-visible\\:outline-none.border-2.border-white.bg-transparent.h-10.w-10.text-white.border-white.hover\\:bg-white.hover\\:text-black.npbutton {
  height: 3.75rem !important;
  width: 3.75rem !important;
  border-radius: 0 !important;
}

.rounded-full-i{
  border-radius: 50px !important;
}
.Reserve-Now{
  background-color: #1E1E1E !important;
  border-radius: 50px !important;
}
.Reserve-Now:hover{
  background-color: #2e2e2e !important;
  border-radius: 50px !important;
}
@media (max-width: 768px) {
  header{
    padding: 0 25px !important;
  }
  .mobile-menu-style a{
    padding: 0 !important;
    height: 30px !important;
  }
  .mobile-menu-style img{
   max-width: 90% !important;
  }
  .mobile-menu-style nav button svg{ 
    height: 2.0rem !important;
    width: 2.0rem !important;
  }
  .featured-logo-n button {
    font-weight: 700 !important;
    color: #2b2a2a !important;
  }
  .featured-suites-section-hp h2, .featured-suites-section-hp h2 span{
    font-size: 3rem !important;
  }
  

  .flex.gap-8.items-center {
    flex-direction: column !important;
  }


  .space-y-2.text-gray-800.mb-4 {
    margin-bottom: 2rem !important;
  }

  .flex.flex-wrap.gap-2.pt-5 {
    padding-top: 1rem !important;
  }


  .flex.flex-col.justify-between.my-auto.h-full {
    margin: 1.5rem 0 !important;
  }

  .flex.text-3xl.font-bold.items-center.gap-4 {
    gap: 1rem !important;
  }


  .flex.flex-col.justify-between.items-center {
    width: 100% !important;
    text-align: center;
  }

  .text-3xl.font-bold.text-black.text-center.mb-5 {
    margin-bottom: 1rem !important;
  }


  .w-10.h-10 {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }


  .text-3xl.md\:text-4xl {
    font-size: 1.875rem !important;
  }

  .text-3xl.font-bold {
    font-size: 1.5rem !important;
  }
}
.savi-medium{
  font-family: 'Serotiva', sans-serif;
  font-weight: 500 !important;
}

.font-helvetica {
    font-family: Helvetica, Arial, sans-serif !important;
}

.room-card {
  background-color: #d9d9d9;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.room-card-container {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.room-image-section {
  flex: 1;
  position: relative;
}

.room-details-section {
  flex: 1;
}

.room-booking-section {
  flex: 1;
}

.room-image {
  border-radius: 0.5rem;
  object-fit: cover;
  width: 100%;
  max-height: 20rem;
}

@media (max-width: 768px) {
  .room-card {
    padding: 1rem;
  }

  .room-card-container {
    flex-direction: column;
    gap: 1.5rem;
  }

  .room-image-section,
  .room-details-section,
  .room-booking-section {
    width: 100%;
  }

 
  .booking-controls {
    width: 100%;

  }

  .booking-controls-container {
    flex-direction: row;
  }

  .booking-section {
    width: 100%;
    text-align: center;
    margin: 1.5rem 0;
  }


  
  .circle-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

 
  .room-title {
    font-size: 1.875rem;
  }

  .price-text {
    font-size: 1.5rem;
  }
  .room-image-section{
    border-radius: 24px;
  }
  .room-card{
    border-radius: 28px;
  }
  .room-details-section{
    padding: 0 0.75rem;
  }
  .booking-controls-container{
    margin-top: 15px;
    align-items: self-end;
  }
  .booking-controls-container p{
    font-size: 0.8rem;
  }
  .booking-controls-container .lucide.lucide-circle-minus  {
    width: 2.1rem !important;
    height: 2.1rem !important;
  }  .booking-controls-container .lucide.lucide-circle-plus  {
    width: 2.1rem !important;
    height: 2.1rem !important;
  }

}
@media (max-width: 768px) {
  .mobile-image-overlay {
    position: relative;
  }

  .mobile-image-overlay::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  }
  .d-none{
    display: none !important;
  }
}
.react-datepicker-wrapper {
  z-index: 50;
}

.react-datepicker-popper {
  z-index: 60 !important;
}

.react-datepicker {
  border: none !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  font-family: inherit;
}

.react-datepicker__month-container {
  border-radius: 0.5rem;
  overflow: hidden;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: 1px solid #e5e7eb !important;
  padding: 0.75rem 0 !important;
}

.react-datepicker__day-names {
  margin-bottom: -8px !important;
}

.react-datepicker__day {
  margin: 0.2rem !important;
}

.react-datepicker__day--selected {
  background-color: black !important;
  color: white !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: black !important;
}

.react-datepicker__day:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.concierge-hero-title {
  font-family: 'Serotiva', sans-serif !important;
  font-style: italic !important;
  font-weight: 700 !important;
  margin-top: 2rem !important;
  letter-spacing: -0.02em !important;
  line-height: 1.1 !important;
}

.text-5xl.md\:text-7xl.font-light.tracking-tight.mt-5.concierge-hero-title {
  font-family: 'Serotiva', sans-serif !important;
  font-style: italic !important;
  font-weight: 700 !important;
}

.con-pill-btn{
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
}


.npbutton2{
 border: 4px solid white !important;
 padding: 10px !important;
}
.npbutton2:hover{
 border: 4px solid #2e2e2e !important;
 background-color: #2e2e2e !important;
}
.npbutton2 svg{
  color: #dbdbdb !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}
.slick-prev:before, .slick-next:before{
  color: #121212 !important;
}